.card-number {
  font-size: 2.6rem;
  font-family: 'Alfa Slab One', cursive;
}

.covid-logo {
  height: 12vh;
}

.cases-info {
  background-color: #0528c7;
}

/* iphones and tablets */

@media screen and (max-width: 768px){
  #home-covid{
    height: 100vh
  }
  .header h1{
    font-size: 1.6rem;
  }
  .header h3{
    font-size: 1.3rem;
  }
  .card-number {
    font-size: 1.7em;
  }

}

