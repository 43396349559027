.axis-label {
  font-size: 0.6rem;
}

g text {
  font-size: 0.7rem;
}

.frame-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-size: 2rem;
}

.rbt-input-main {
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
}

.tooltip-content {
  background-color: white;
  border: 3px solid #ddd;
  padding: 1em;
  text-align: center;
}

.annotation-layer {
  font-weight: bold;
  font-size: 0.6rem;
}

#graphs {
  display: block;
  /* visibility: visible; */
}

#graph-for-mobile {
  display: none;
  /* visibility: hidden; */
}

@media screen and (max-width: 992px){    
  #graphs {
    display: none;
  }

  #graph-for-mobile {
    display:inline ;
  }
}

@media screen and (max-width: 768px){
  .axis-label {
    font-size: 0.5rem
  }
  .leyenda {
    font-size: 0.7rem;
  }
  .frame-title {
    font-size: 0.85rem;
  }
  .btn {
    font-size: 0.8rem;
  }
}


