body {
    font-family: 'Poppins', sans-serif;
}

.nav-bar{
    background-color: rgba(193, 191, 240, 0.29);
}

#dropdown-item-button{
    background-color: #1D1A05;
    border: none;
    color: white;
    padding: .375rem 1.5rem;
    font-size: 0.7rem
}

