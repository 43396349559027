.country-name{
  font-weight: bold;
  font-size: 3.5em;
}

.country-info{
  height: 9rem;
  width: 30rem;
}

.number {
  font-size: 2em;
  font-family: 'Alfa Slab One', cursive;
}

.icon img {
  height: 3rem;
  float: right
}
